import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Container from "../../../components/container"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import {SegmentSubTitle, SegmentText } from "../../../components/segment"
import Screenshot from "../../../components/screenshot"
import SubText from "../../../components/subtext"
import FlexColumnWrapper from "../../../components/flexcolumnwrapper"
import MenuItem from "../../../components/menuitem"
import SupportCta from "../../../components/supportcta"

import { useTheme } from "../../../services/theme"


const HubspotConnectFaq = ({data}) => {
    

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="FAQ - How do I connect a Hubspot account to Vimkit?"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="10vh"
                    category="FAQ"
                    title="How do I connect a Hubspot account to Vimkit?"
                    maxWidth="960px"
                />
                <FlexColumnWrapper 
                    marginBottom="5%"
                    maxWidth="480px"
                    marginLeft="auto"
                    marginRight="auto"
                    breakpoint={theme.breakpoints.mobile}
                    responsiveStyle="padding-left: 20px; padding-right: 20px;"
                >
                    <SegmentSubTitle>Step 1: Create connection </SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Websites' and choose the website you'll be using with Hubspot.<br/><br/>
                        &#8594;Click 'Add Form'.<br/><br/>
                        &#8594;Next click 'Connect Hubspot Account'.<br/><br/>
                        <SubText align="left"> You will be prompted to sign into your Hubspot account if you are not signed in.</SubText>
                    </SegmentText>
                    <Screenshot fluid={data.connectHubspotScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="40px"/>
                    <SegmentSubTitle>Step 2: Grant access</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Grant Access' to give Vimkit access to your Hubspot forms. 
                        <SubText align="left">You must be a Super Admin on the Hubspot account to grant access to Vimkit.</SubText>
                    </SegmentText>
                    <Screenshot fluid={data.grantHubspotScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="20px"/>
                    <SegmentText marginTop="0px" marginBottom="20px">
                        &#8594;Once your Hubspot account is connected, Vimkit will automatically list all forms available in your Hubspot account.
                    </SegmentText>
                </FlexColumnWrapper>
            </Container>
            <SupportCta/>
            
        </SiteLayout>    
    )
}

export default HubspotConnectFaq

export const query = graphql`
    query {
        connectHubspotScreenshot: file(relativePath: { eq: "screenshots/connecthubspot.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        grantHubspotScreenshot: file(relativePath: { eq: "screenshots/granthubspot.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`