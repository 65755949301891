import styled from "styled-components"

export default styled.div`
    display: ${({hide, display}) => hide ? "none" : (display || "block")};
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    padding-top: 0px;
    padding-bottom: 5px;
    font-weight: 400;
    font-size: 12px;
    color: ${({theme}) => theme.colors.subtleText};
    text-align: ${({align}) => align || "center"};;
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}

    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        ${({responsiveAlign}) => responsiveAlign ? `text-align: ${responsiveAlign};` : ""}
    
    }
`